/////////////////////
// Bootstrap

// Redefine Bootstrap sizing function to add more options (33 & 66)
$sizes: () !default;
$sizes: map-merge(
  (
    25: 25%,
    33: 33%,
    50: 50%,
    66: 66%,
    75: 75%,
    100: 100%,
    auto: auto
  ),
  $sizes
);

// responsive font sizes
//@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans");
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;700&display=swap');
//@import url("https://fonts.googleapis.com/css2?family=Hind:wght@300;600&display=swap");
$enable-responsive-font-sizes: true;
$font-size-base: 1.15rem; 
$font-family-sans-serif: 'Nunito Sans', sans-serif;

$font-weight-regular: 300;
$font-weight-bold: 700;


// Import boostrap CSS
@import 'bootstrap/bootstrap';


//////////////////
// Lineawesome
$la-font-path: "/assets/fonts";
@import 'lineawesome/line-awesome';


////////////////////
// Custom CSS Below


//////////////////////////////
// Define FocusOne Color Scheme
$focus_blue: #1c2466;
$focus_yellow: #f28e17;
$focus_grey: #818386;
$focus_black: #000000;

$primary:       $focus_blue;
$secondary:     $focus_yellow;
$success:       $focus_grey;
$info:          $focus_grey;
$warning:       $focus_yellow;
$danger:        $red;
$light:         #e4e4e5;
$dark:          #focus_black;

body{
   font-weight: $font-weight-regular;
   background: $light;
}


///////////////////
// Fluid logo - make the logo slightly larger than the container
//   this resolves collapsing issues with the menu bar, while
//   keeping the desired asthetic
.logo {
    transform: translate(15%, 10%) scale(1.3) ; /* Standard syntax */
    -ms-transform: translate(15%, 10%) scale(1.3); /* IE 9 */
    -webkit-transform: translate(15%, 10%) scale(1.3); /* Safari */
}


//////////////////////
// Slogan in the header block
.slogan {
    font-size: 2.5em;
    padding-right: 1rem;
    font-weight: $font-weight-bold;
    color: #aaa;
}

/////////////////////
// Shrink navbar text slightly for medium
// this helps with collapsing
@include media-breakpoint-only(md) { 
    .nav-link {
        font-size: 1.12rem !important;
    }
    .slogan {
        font-size: 2.0em !important;
    }
}

// Workaround for broken form validation
// when using an input group
.fix-rounded-right {
  border-top-right-radius: .25rem !important;
  border-bottom-right-radius: .25rem !important;
}

//////////////////////
// Give some heft to strong
strong {
    font-weight: $font-weight-bold;
}

//////////////////////
// Bottom Borders for headings
h1 {
    border-bottom-style: solid;
    font-weight: $font-weight-bold;
}
h2 {
    border-bottom: 0.1rem solid #aaa;
    font-weight: $font-weight-bold;
}
h3 {
    font-weight: $font-weight-bold;
}

//////////////////////
// Bold the menu items in the header
.nav-link {
    font-weight: $font-weight-bold;
}

//////////////////////
// Colors to cycle through, used to alternate heading bottom border colors.
.color-0 {
    border-bottom-color: $focus_blue;
}  
.color-1 {
    border-bottom-color: $focus_yellow;
}  
.color-2 {
    border-bottom-color: $focus_grey;
}  
.color-3 {
    border-bottom-color: $focus_blue;
} 
.color-4 {
    border-bottom-color: $focus_yellow;
} 
.color-5 {
    border-bottom-color: $focus_grey;
}  
.color-6 {
    border-bottom-color: $focus_blue;
} 
.color-7 {
    border-bottom-color: $focus_yellow;
}  
.color-8 {
    border-bottom-color: $focus_grey;
}  
.color-9 {
    border-bottom-color: $focus_blue;
} 


////////////////////////
// YouTube Embedding
.embed-container { 
    position: relative; 
    padding-bottom: 56.25%; 
    height: 0; 
    overflow: hidden; 
    max-width: 100%; 
} 
.embed-container iframe, .embed-container object, .embed-container embed { 
    position: absolute; 
    top: 0; left: 0; 
    width: 100%; height: 100%; 
}



